@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.55;
}
@media (max-width: 1720px) {
  * {
    font-size: 14px;
  }
}

h1 {
  font-size: 28px;
  font-weight: 700;
}

.offline{
  font-size: 28px;
  font-weight: 700;
  color:#fff
}
@media (max-width: 1720px) {
  h1 {
    font-size: 24px;
  }
}
h1 + * {
  margin-top: 10px;
}
h1 + .form__control {
  margin-top: 25px;
}

h2 {
  font-size: 21px;
}
h2 + * {
  margin-top: 20px;
}

h3 {
  font-size: 18px;
}

h6 {
  display: block;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.01em;
}
h6 + * {
  margin-top: 30px;
}

.-color-1 {
  color: #2e416d;
}

a {
  text-decoration: none;
}

::selection {
  background: rgba(46, 65, 109, 0.8);
  color: white;
}

body {
  height: 100%;
  margin: 0 0 0 auto;
}

body main {
  width: calc(100% - 270px);
  height: 100%;
  margin: 0 0 0 auto;
}

.nav {
  position: fixed;
  top: 0;
  padding: 55px 50px;
  width: 270px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #2e416d;
}
.nav__logo {
  display: block;
  margin-bottom: 25px;
}
.nav__logo img {
  display: block;
  margin: auto;
  width: 130px;
}
.nav__logout {
  position: relative;
  z-index: 1;
  margin-top: 55px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.nav__logout:hover {
  cursor: pointer;
}

@media (max-width: 1720px) {
  .nav__logout {
    font-size: 16px;
  }
}
.nav__logout img {
  width: 26px;
  margin-left: 6px;
  margin-right: 24px;
}
.nav hr {
  opacity: 0.8;
  margin-top: 24px;
  margin-bottom: 24px;
}
.nav::before {
  content: "";
  position: absolute;
  bottom: 138px;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/logout-shape.2609c352.svg);
  background-position: bottom;
}
.nav::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 140px;
  background-color: #46567b;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 60px 80px 80px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

section {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0px;
  padding-left: 0px;
}
section + section {
  margin-top: 80px;
}
section:last-of-type {
  padding-bottom: 120px;
}

.form__control label {
  display: block;
  margin-bottom: 8px;
  color: #2e416d;
  font-weight: 500;
  font-size: 14px;
}
.form__control input,
.form__control select {
  width: 100%;
  border-radius: 4px;
  border: none;
  height: 55px;
  padding: 0 18px;
  appearance: none;
  -webkit-appearance: none;
  background-color: #efeff6;
  outline: none;
}
.form__control select {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/arrow-down.f9266c25.svg);
  background-size: 12px;
  background-position: center right 20px;
  cursor: pointer;
}
.form__control.-error input {
  background-color: rgba(239, 68, 92, 0.1);
}
.form__control + .button {
  margin-top: 30px;
}
.form__control + .form__control {
  margin-top: 30px;
}
.form__error {
  margin-top: 10px;
  font-size: 12px;
  color: #ef445c;
}

.form__success {
  margin-top: 10px;
  font-size: 12px;
  color: #6dd295;
}

.toggle label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.toggle.-close label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.toggle__switch {
  position: relative;
  transition: background-color 300ms ease-out;
  width: 50px;
  height: 26px;
  border-radius: 20px;
  background-color: #efeff6;
}
.toggle__check {
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 300ms ease-out;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: white;
}
.toggle input {
  position: absolute;
  width: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.toggle input:checked + label .toggle__switch {
  background-color: #2e416d;
}
.toggle input:checked + label .toggle__check {
  left: 28px;
}

.file {
  display: block;
  margin-top: 25px;
  margin-bottom: 80px;
}

.file .button {
  margin-right: 10px;
}
/* .file input[type="file"] {
  text-indent: -128px;
} */
@media (max-width: 1720px) {
  .file input[type="file"] {
    text-indent: -115px;
  }
}

.expandedrow {
  height: 640px;
}

.search {
  border: none;
  border-bottom: 1px solid #2e416d;
  width: 50%;
  padding: 0 0 12px 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/zoeken.e3658904.svg);
  background-size: 16px;
  background-position: top 3px left;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
}
.search::placeholder {
  color: #2e416d;
  opacity: 0.4;
}

.status {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 18px;
  font-weight: 600;
  color: #ef445c;
  padding: 0;
  border: none;
  background: none;
}

.status:not(.-success) .status__text:hover {
  opacity: 0.6;
  cursor: pointer;
}

.status .status__text .spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

@media (max-width: 1720px) {
  .status {
    font-size: 14px;
  }
}
.status::before {
  content: "";
  position: absolute;
  top: -65px;
  right: -155px;
  z-index: -1;
  opacity: 0.6;
  pointer-events: none;
  height: calc(100% + 105px);
  width: 100vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/shape-status.95d28836.svg);
  background-position: right;
}
.status__dot {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: #ef445c;
  display: inline-block;
}

@media (max-width: 1720px) {
  .status__dot {
    width: 8px;
    height: 8px;
  }
}
.status.-success {
  color: #6dd295;
}
.status.-success .status__dot {
  background-color: #6dd295;
}

.menu {
  width: 100%;
}
.menu__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: border-color 300ms ease-out, background-color 300ms ease-out;
  width: 36px;
  height: 36px;
  border: 1px solid white;
  border-radius: 100%;
  margin-right: 12px;
}
@media (max-width: 1720px) {
  .menu__icon {
    width: 32px;
    height: 32px;
  }
}
.menu__icon svg {
  height: 40%;
  width: auto;
  max-width: 14px;
  fill: white;
}
.menu__item {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.menu__item:hover {
  cursor: pointer;
}

@media (max-width: 1720px) {
  .menu__item {
    font-size: 12px;
  }
}
.menu__item + .menu__item {
  margin-top: 12px;
}
.menu__item:hover .menu__icon,
.menu__item.-active .menu__icon {
  border-color: #b7e3ea;
  background-color: #b7e3ea;
}
.menu__item:hover .menu__icon svg,
.menu__item.-active .menu__icon svg {
  fill: #2e416d;
}

.overview {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: -20px;
}
.overview__item {
  position: relative;
  overflow: hidden;
  width: calc(100% / 3 - 20px);
  padding: 40px;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #efeff6;
}

.tasklist__item {
  position: relative;
  overflow: hidden;
  width: calc(100% / 3 - 20px);
  padding: 40px;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 0 5px 15px 0 rgba(50, 67, 109, 0.4);

}

@media (max-width: 1440px) {
  .overview__item {
    padding: 35px;
  }
}
@media (max-width: 1280px) {
  .overview__item {
    width: calc(50% - 20px);
  }
}
.overview__item *:not(.button) {
  color: #2e416d;
}
.overview__item .button {
  margin-top: 35px;
  width: 100%;
}
.overview__title {
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 10px);
  white-space: nowrap;
}
.overview__time {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 130px;
  height: 115px;
  background-image: url(/static/media/overview-time.e4edf47b.svg);
  background-position: right top;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}
.tasklist__time {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}

.tasklist__button {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  box-shadow: none;
  border: 1px solid #2e416d;
  padding: 5px 10px;
  background-color: transparent;
  color: #2e416d;
  font-size: 14px;
  border-radius: 28px;
  transition: box-shadow 300ms ease-out, background-color 300ms ease-out;
   box-shadow: 0 5px 10px 0 rgba(50, 67, 109, 0.1);
  border: none;
  background-color: #6dd295;
  cursor: pointer;
  color: white;
}


.tasklist__button.-small {
  box-shadow: none;
  border: 1px solid #2e416d;
  padding: 5px 10px;
  background-color: transparent;
  color: #2e416d;
  font-size: 14px;
  cursor: default;

}


.tasklist__button.-small:hover {
  background-color: transparent;
  color: #2e416d;
}

.tasklist__button:hover {
  background-color: #2e416d;
  color: white;
}



@media (max-width: 1440px) {
  .overview__time {
    width: 120px;
    height: 105px;
  }
}
.overview__time .small {
  font-size: 10px;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 300ms ease-out, background-color 300ms ease-out;
  border-radius: 28px;
  padding: 15px 32px;
  box-shadow: 0 5px 10px 0 rgba(50, 67, 109, 0.1);
  border: none;
  background-color: #6dd295;
  cursor: pointer;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}
.searchBar
{

}

@media (max-width: 1720px) {
  .button {
    font-size: 14px;
  }
}


.button img {
  display: block;
  width: auto;
  height: 16px;
  margin-right: 15px;
}
.button svg {
  display: block;
  width: auto;
  height: 16px;
  margin-right: 15px;
}

.button:hover {
  box-shadow: 0 15px 20px 0 rgba(50, 67, 109, 0.3);
  background-color: #2e416d;
}
.button.-red {
  background-color: #ef445c;
}
.button.-transparent {
  transition: opacity 300ms ease-out;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  color: #2e416d;
}
.buttons .button.-transparent {
  width: calc(30% - 5px);
  margin-right: 10px;
  margin-bottom: 15px;
}
.button.-transparent img {
  height: 20px;
}
.button.-transparent:hover {
  opacity: 0.6;
}
.button.-wide {
  width: 100%;
}

.button.-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #beffd8;
}



.spinning {
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.button.-small {
  box-shadow: none;
  border: 1px solid #2e416d;
  padding: 10px 15px;
  background-color: transparent;
  color: #2e416d;
  font-size: 14px;
}
@media (max-width: 1720px) {
  .button.-small {
    font-size: 13px;
  }
}
.button.-small:hover {
  background-color: #2e416d;
  color: white;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: -10px;
}
.buttons .button {
  width: calc(50% - 10px);
  margin-right: 10px;
  margin-bottom: 15px;
}
.buttons .button.-small {
  width: calc(100% / 3 - 10px);
}

.buttons .button.-wide {
  width: 100%;
  background-color: #2e416d;

}

.buttons .button.-wide.-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #beffd8;
}
.buttons + .buttons {
  margin-top: 15px;

}

._table {
  width: 100%;
  border-collapse: collapse;
  border: none;
  text-align: left;
  table-layout: auto;
}
._table thead th {
  border-bottom: 1px solid #2e416d;
  padding: 0 0 20px 20px;
  color: #2e416d;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
._table tbody tr td {
  transition: background-color 300ms ease-out, box-shadow 300ms ease-out 0ms;
  padding: 25px 0 25px 20px;
  border-top: 1px solid rgba(46, 65, 109, 0.25);
}
._table tbody tr td:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
._table tbody tr td:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
/* ._table tbody tr td img, */
._table tbody tr td svg {
  height: 12px;
  float: right;
}
._table tbody tr td:last-of-type {
  padding-right: 20px;
}
._table tbody tr:last-of-type td {
  border-bottom: 1px solid rgba(46, 65, 109, 0.25);
}
.table__filter {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.table__filter input {
  flex-grow: 1;
  border: none;
  margin-right: 10px;
  border-radius: 6px;
  border-radius: 28px 6px 6px 28px;
  padding: 15px 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #efeff6;
  background-image: url(/static/media/zoeken.e3658904.svg);
  background-size: 15px;
  background-position: center left 22px;
  outline: none;
}
.table__filter input::placeholder {
  color: #2e416d;
  opacity: 0.4;
}
.table__filter .button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.table__filter .middle__button {
  border-radius: 4px;
  margin-right: 10px;

}
.table__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.react-bootstrap-table-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
._table.-clickable {
  table-layout: auto;
}
._table.-clickable tr td {
  cursor: pointer;
}
._table.-clickable tr:hover td {
  transition: background-color 300ms ease-out, box-shadow 300ms ease-out 200ms;
  border-top: 1px solid transparent;
  box-shadow: 0 15px 20px 0 rgba(50, 67, 109, 0.3);
  background-color: #2e416d;
  color: white;
}
._table.-clickable tr:hover td svg {
  fill: white;
}
._table + p {
  padding-left: 20px;
  margin-top: 25px;
}
._table + .button {
  margin-top: 20px;
}
._table.-small {
  margin-top: 20px;
}
._table.-small td {
  padding: 12px 0;
  text-transform:capitalize;
}
._table.-small td:first-child {
  font-weight: 500;
}
._table.-medium {
  margin-top: 40px;
}
._table.-medium th {
  padding-bottom: 17px;
}
._table.-medium td {
  padding-top: 17px;
  padding-bottom: 17px;
}
._table.-minimal td {
  padding: 7px 0;
  border-bottom: none !important;
  border-top: none;
}

.pagination {
  display: flex;
}
.pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease-out;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid black;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
.pagination__item img {
  height: 10px;
  width: auto;
}
.pagination__item:hover,
.pagination__item.-active {
  background-color: #b7e3ea;
}
.pagination__item + .pagination__item {
  margin-left: 10px;
}

.dropdown {
  position: relative;
  height: 35px;
  width: 63px;
  border-radius: 17px;
  border: 1px solid black;
  cursor: pointer;
}

.dropdown__assign {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: none;
  height: 55px;
  padding: 10px 18px;
  /* appearance: none; */
  background-color: #efeff6;
  cursor: pointer;
  font-size: 20px;


}
.dropdown__assign {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: none;
  height: 55px;
  padding: 10px 18px;
  /* appearance: none; */
  background-color: #efeff6;
  cursor: pointer;
  font-size: 20px;


}

.dropdown__list__assign {
  position: absolute;
  top: 100%;
  left: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  /* border: 1px solid transparent; */
  /* padding: 2px; */
  background-color: #efeff6;
  width: 380px;
}

.dropdown__assign::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  display: block;
  transition: transform 300ms ease-out;
  margin: auto;
  width: 8px;
  height: 8px;
  background-image: url(/static/media/arrow-down.f9266c25.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


.dropdown__option__assign label {
  display: none;
  padding: 0 15px;
  margin-top: 5px;
  cursor: pointer;
  line-height: 1;
  font-size: 18px;
}
.dropdown__option__assign input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.dropdown__option__assign input:checked + label {
  position: absolute;
  top: -29px;
  overflow: hidden;
  display: block;
  max-width: calc(100% - 10px);
  pointer-events: none;
  font-weight: 500;
  text-overflow: ellipsis;
}

.dropdown__list {
  position: absolute;
  top: 100%;
  left: -1px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid transparent;
  width: calc(100% + 2px);
}
.dropdown__option label {
  display: none;
  padding: 0 15px;
  margin-top: 5px;
  cursor: pointer;
  line-height: 1;
  font-size: 14px;
}
.dropdown__option input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.dropdown__option input:checked + label {
  position: absolute;
  top: -29px;
  overflow: hidden;
  display: block;
  max-width: calc(100% - 10px);
  pointer-events: none;
  font-weight: 500;
  text-overflow: ellipsis;
}
.dropdown::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  display: block;
  transition: transform 300ms ease-out;
  margin: auto;
  width: 8px;
  height: 8px;
  background-image: url(/static/media/arrow-down.f9266c25.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.-carret {
  content: "";
  display: inline-block;
  transition: transform 300ms ease-out;
  margin: auto;
  width: 8px;
  height: 8px;
  background-image: url(/static/media/arrow-down.f9266c25.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.-carret.-open {
  transform: rotate(180deg);
}

.dropdown.-open {
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown.-open .dropdown__list {
  padding-bottom: 10px;
  border: 1px solid black;
  border-top: 1px solid transparent;
}
.dropdown.-open .dropdown__option  label {
  display: block;
}
.dropdown.-open::after {
  transform: rotate(180deg);
}

.dropdown__assign.-open {
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown__assign.-open .dropdown__list__assign {
  padding-bottom: 10px;
  border-top: 1px solid transparent;
}
.dropdown__assign.-open .dropdown__option__assign label {
  display: block;
}
.dropdown__assign.-open::after {
  transform: rotate(180deg);
}


.login {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #2e416d;
}
.login__logo {
  position: fixed;
  left: 75px;
  top: 55px;
  width: 130px;
  height: auto;
}
.login__container {
  position: relative;
  padding: 50px 55px;
  border-radius: 4px;
  box-shadow: 0 15px 20px 0 rgba(50, 67, 109, 0.3);
  width: 420px;
  background-color: white;
}
.login__container::before {
  content: "";
  position: absolute;
  top: -60px;
  left: -170px;
  pointer-events: none;
  margin: auto;
  height: calc(100% + 120px);
  width: calc(100% + 340px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/login-shape.442ac712.svg);
}

.link {
  display: block;
  transition: color 300ms ease-out, border-color 300ms ease-out;
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  margin: 20px auto 0;
  border-bottom: 1px solid currentColor;
  color: #2e416d;
  font-size: 14px;
}
.link:hover {
  cursor: pointer;
  color: #6dd295;
}

.model {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: scroll;
  width: 100%;
  height: 100%;
  padding: 10vh 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.model__container {
  position: relative;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 0;
  transform: translateY(40px);
  width: 480px;
  margin: auto;
  padding: 50px;
  border-radius: 6px;
  background-color: white;
}
.model__cross {
  position: absolute;
  right: 50px;
  top: 62px;
  width: 18px;
  cursor: pointer;
}
.model__divider {
  margin: 40px 0 20px;
  width: 100%;
  height: 1px;
  background-color: #2e416d;
}
.model.-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.model.-active .model__container {
  opacity: 1;
  transform: translateY(0px);
}

.model.-large {
  z-index: 2;
}

.model.-large .model__container {
  width: 740px;
}

/* .container{
  margin-left: 0;
  margin-right: 0;
  max-width: 1800px;

} */
.tab {
  display: flex;
  align-items: flex-start;
}
.tab__menu {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  width: calc((100% - 400px) / 2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.tab__item {
  display: block;
  transition: opacity 300ms ease-out;
  opacity: 0.2;
  margin-left: -1px;
  border-left: 1px solid transparent;
  padding: 8px 0 8px 30px;
  cursor: pointer;
  color: #000000;
  font-weight: 600;
}
.tab__item:first-of-type {
  padding-top: 0;
}
.tab__item:last-of-type {
  padding-bottom: 0;
}
.tab__item.-active,
.tab__item:hover {
  opacity: 1;
}
.tab__item.-active ~ .tab__item:not(:hover),
.tab__item:hover ~ .tab__item:not(:hover) {
  opacity: 0.2;
}
.tab__item.-active {
  border-left: 1px solid #000000;
}
.tab__item.-active ~ .tab__item {
  border-left: 1px solid transparent;
}
.tab__plane {
  width: 400px;
}
.tab__panel + .tab__panel {
  margin-top: 80px;
}

.board {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}
.board__holder {
  box-shadow: 0 10px 20px 0 rgba(50, 67, 109, 0.4);
  width: 66%;
  padding: 45px;
  border-radius: 6px;
  margin-right: 50px;
}
.parking__holder {
  box-shadow: 0 10px 20px 0 rgba(50, 67, 109, 0.4);
  width: 100%;
  padding: 25px;
  border-radius: 6px;
  margin-right: 25px;
}
.board__holder_row {
  margin: 0px;
  margin-right: 0px;
  padding: 5px;
  background-image: linear-gradient(0deg, #ffffff 2%, #32436d 2%, #32436d 48%, #ffffff 48%, #ffffff 53%, #32436d 50%, #32436d 97%);
}

.board__holder_column {
  margin: 0px;
  margin-right: 0px;
  padding: 5px;
  background-image: linear-gradient(90deg, #ffffff 0.5%, #32436d 0.5%, #32436d 9%, #ffffff 9%, #ffffff 10.5556%, #32436d 10.5556%, #32436d 19.0556%, #ffffff 19.0556%, #ffffff 20.6111%, #32436d 20.6111%, #32436d 29.1111%, #ffffff 29.1111%, #ffffff 30.6666%, #32436d 30.6666%,
  #32436d 39.1666%, #ffffff 39.1666%, #ffffff 40.7222%, #32436d 40.7222%, #32436d 49.2222%, #ffffff 49.2222%, #ffffff 50.7777%, #32436d 50.7777%, #32436d 59.2777%, #ffffff 59.2777%, #ffffff 60.8333%, #32436d 60.8333%,
  #32436d 69.3333%, #ffffff 69.3333%, #ffffff 70.8888%, #32436d 70.8888%, #32436d 79.3888%, #ffffff 79.3888%, #ffffff 80.9444%, #32436d 80.9444%, #32436d 89.4444%, #ffffff 89.4444%, #ffffff 90.9999%, #32436d 90.9999%, #32436d 99.5%, #ffffff 99.5%)}

@media (max-width: 1720px) {
  .board__holder {
    padding: 40px;
    margin-right: 30px;
  }
}
.board__row {
  display: flex;
  justify-content: space-between;
  background-color: #32436d;
  padding: 2px;

}
.board__row + .board__row {
  margin-top: 12px;
}
.board__pin {
  position: relative;
  transition: border-color 300ms ease-out;
  border-radius: 100%;
  width: calc(10% - 12px);
  padding-top: calc(10% - 16px);
  height: 0;
  border: 2px solid #efeff6;
  background-color: #efeff6;
  cursor: pointer;
}
.board__pin.-green {
  border-color: #6dd295;
  background-color: #6dd295;
  color: white;
}

.board__pin.-error {
  border-color: #ef445c;
  background-color: #ef445c;
  color: white;
}

.board__pin:hover {
  border: 2px solid rgba(46, 65, 109, 0.4);
}
.board__pin.-active {
  border: 2px solid #2e416d;
}
.board__number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 1720px) {
  .board__number {
    font-size: 16px;
  }
}
.board__card {
  width: 33%;
  padding: 50px;
  border-radius: 6px;
  transition: opacity 300ms ease-out;
  background-color: #efeff6;
}
@media (max-width: 1720px) {
  .board__card {
    padding: 40px;
  }
}
.board__card.-active {
  opacity: 1;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  transition: opacity 300ms ease-out;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #2e416d;
}
.breadcrumbs img {
  width: 20px;
  margin-right: 10px;
}
.breadcrumbs:hover {
  opacity: 0.8;
}

/*# sourceMappingURL=style.css.map */

.progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: #455A64;
  padding-left: 0px;
  margin-top: 30px;
  text-align: center;

}

.progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 33%;
  height: 52px;
  /* float: left; */
  position: relative;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 50px;
}
.progressbar li:hover {
  cursor: pointer;

}

.progressbar li:last-child {
  margin-bottom: 120px;

}


.progressbar .step0:before {
  font-family: FontAwesome;
  content: "\f10c";
  color: #fff
}

.progressbar li:before {
  width: 40px;
  height: 40px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  background: #C5CAE9;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
  position: absolute;
  left: 200px;
  top:-10px;
}

.progressbar li:not(:last-child):after {
  content: '';
  width: 12px;
  height: 72px;
  background: #C5CAE9;
  position: absolute;
  left: 214px;
  top: 28px;
  z-index: -1
}


/* .progressbar li:nth-child(2):after,
.progressbar li:nth-child(3):after {
  left: -50%
} */

.progressbar li.active:before,
.progressbar li.active:after {
  background: #2e416d
}
/* .progressbar li.active {
  background: #2e416d
} */

.progressbar li.active:before {
  font-family: FontAwesome;
  content: "\f00c"
}

.event__card {
  width: 50%;
  padding: 15px;
  position: absolute;
  display: flex;
  margin-left: 340px;
  margin-top: 130px;
  left: 0;
  top:0;
  flex-wrap: wrap;
  border-radius: 6px;
  transition: opacity 300ms ease-out;
  background-color: #efeff6;
}
.event__card__table {
  width: 100%;
}

.event__card__image {
  width: 100%;
  /* position: "relative"; */
}

.QR_div {
  width:50%;
  margin-right:auto;
  margin-left: auto;
  margin-top:16px;
  margin-bottom:16px;

  display: flex;
align-items: center;
justify-content: center;
}

.btn-lg, .btn-group-lg > .btn {

  border-radius: 0.3rem;
  font-size: 19px;
  padding-left: 8px;
  padding-right: 8px;

}

.btn-sm, .btn-group-sm > .btn {

  border-radius: 0.2rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}



.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}


.button.active{
  background-color: #ffbebe;
}

.parking__row {
  display: flex;
/*  justify-content: space-between;
  /* background-color: #32436d; */
  padding: 2px;

}

.parking__pin {
  position: relative;
  transition: border-color 300ms ease-out;
  width: calc(5% - 12px);
  padding-top: calc(5% - 8px);
  margin-left: calc(3% - 8px);
  margin-right: 0;
  height: 0;
  border: 2px solid #909090;
  border-left-style: none;
  background-color: #efeff6;
  cursor: pointer;
}

.parking__pin:nth-child(2n) {
  /* margin-right: calc(5% - 16px); */
  border-right-style: none;
  margin-left:0;
}

.parking__number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}

.parking__pin.-green {
  border-color: #6dd295;
  background-color: #6dd295;
  color: white;
}

.parking__pin.-error {
  border-color: #ef445c;
  background-color: #ef445c;
  color: white;
}

.parking__pin:hover {
  border: 2px solid #ff220066;
}
.parking__pin.-active {
  border: 2px solid #2e416d;
}

.username__text {
  width: 200px;
  word-wrap: break-word;
}

.zone__row {
  display: flex;
/*  justify-content: space-between;
  /* background-color: #32436d; */
  padding: 2px;

}

.zone__pin {
  position: relative;
  transition: border-color 300ms ease-out;
  width: calc(10% - 12px);
  padding-top: calc(5% - 8px);
  margin-left: calc(1% - 4px);
  margin-right:  calc(1% - 4px);
  height: 0;
  border: 2px solid #909090;
  /* border-left-style: none; */
  background-color: #efeff6;
  cursor: pointer;
}

.zone__title {
  position: relative;
  width: calc(10% - 12px);
  padding-top: calc(5% - 8px);
  margin-left: calc(1% - 4px);
  margin-right:  calc(1% - 4px);
  height: 0;
  /* border-left-style: none; */
 /* background-color: #efeff6;*/
  cursor: pointer;
}

.zone__column_span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}


.zone__number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}

.zone__pin.-green {
  border-color: #6dd295;
  background-color: #6dd295;
  color: white;
}

.zone__pin.-error {
  border-color: #ef445c;
  background-color: #ef445c;
  color: white;
}

.zone__pin:hover {
  border: 2px solid #ff220066;
}
.zone__pin.-active {
  border: 2px solid #2e416d;
}

.react-bootstrap-table > table > thead > tr > th .filter-label {
  display: block !important; }

.react-bootstrap-table > table > thead > tr > th .filter {
  font-weight: normal; }

.react-bootstrap-table > table > thead > tr > th .select-filter option[value=''],
.react-bootstrap-table > table > thead > tr > th .select-filter.placeholder-selected,
.react-bootstrap-table > table > thead > tr > th .filter::-webkit-input-placeholder,
.react-bootstrap-table > table > thead > tr > th .number-filter-input::-webkit-input-placeholder,
.react-bootstrap-table > table > thead > tr > th .date-filter-input::-webkit-input-placeholder {
  color: lightgrey;
  font-style: italic; }

.react-bootstrap-table > table > thead > tr > th .select-filter.placeholder-selected option:not([value='']) {
  color: initial;
  font-style: initial; }

.react-bootstrap-table > table > thead > tr > th .number-filter,
.react-bootstrap-table > table > thead > tr > th .date-filter {
  display: flex; }

.react-bootstrap-table > table > thead > tr > th .number-filter-input,
.react-bootstrap-table > table > thead > tr > th .date-filter-input {
  margin-left: 5px;
  float: left;
  width: calc(100% - 67px - 5px); }

.react-bootstrap-table > table > thead > tr > th .number-filter-comparator,
.react-bootstrap-table > table > thead > tr > th .date-filter-comparator {
  width: 67px;
  float: left; }

.datepicker {
 margin: 8px;
}
.EventsTable_searchbar__2NAGg {
}

.EventsTable_CSVbutton__aPp4T {
  float: left;
  margin-bottom: 6px;
}

.EventsTable_Table__V-RkB {
  margin: 12px 12px 12px 12px;
}

.EventsTable_Modal__5YFQh {
  margin: 6px 6px 6px 6px;
}

.EventsTable_customDialog__27NlE {
  max-width: 80% !important;
}
.EventsTable_Image__3rI-1 {
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Table_searchbar__1YPvi {
}

.Table_CSVbutton__71yzJ {
  float: left;
  margin-bottom: 6px;
}

.Table_Table__2Ohi4 {
  margin: 12px 12px 12px 12px;
}

.Table_Modal__w0Leu {
  margin: 6px 6px 6px 6px;
}

.Table_customDialog__3bB0k {
  max-width: 80% !important;
}
.Table_Image__1LLDW {
  max-width: 50% !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.ScanPopup_success__3a2B_ {
  background-color: #5cb85c7e;
}

.ScanPopup_danger__3ZdRG {
  background-color: #d9534f7e;
}

.ScanPopup_warning__f8C4F {
  background-color: #f0ad4e7e;
}

@media screen {
	.ScanPopup_printing__3etZh {
		visibility: hidden;
	}
}
.NotFound {
  padding-top: 100px;
}

