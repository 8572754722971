.success {
  background-color: #5cb85c7e;
}

.danger {
  background-color: #d9534f7e;
}

.warning {
  background-color: #f0ad4e7e;
}

@media screen {
	.printing {
		visibility: hidden;
	}
}