.searchbar {
}

.CSVbutton {
  float: left;
  margin-bottom: 6px;
}

.Table {
  margin: 12px 12px 12px 12px;
}

.Modal {
  margin: 6px 6px 6px 6px;
}

.customDialog {
  max-width: 80% !important;
}
.Image {
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
